
.hk-roll {
	background: none;
	border: none;
	padding: 0;

	&:focus {
		outline: none;
	}
}
